import React, { useEffect, useState } from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LayoutProvider } from '../../components/layout';
import { stackStyled } from '../../theme';
import { useViewPortSize } from '../../hooks';
import { IFile } from '../../@types';
import { StackOnThemeT } from '../../theme/StacksOnTheme';
import { SearchManualUI } from '../../components/sections';
import useCoreAPIURL from '../../hooks/useCoreAPIURL';
import RegistrationForm from '../../components/sections/RegisterProducts/RegistrationForm';

export const query = graphql`
  query {
    strapiProductsManuals {
      productCategory {
        subCategories {
          title
          id
          warrantyDoc {
            alternativeText
            caption
            created_at
            ext
            hash
            mime
            name
            provider
            size
            url
          }
        }
        title
      }
    }
  }
`;

interface IProductSubCategories {
  id: number;
  title: string;
  warrantyDoc: IFile;
}

interface IProductCategories {
  title: string;
  id: number;
  subCategories: Array<IProductSubCategories>;
}

interface IProductManuals {
  strapiProductsManuals: { productCategory: Array<IProductCategories> };
}

interface IProductManualsProps {
  isMobile?: boolean;
  $color?: string;
  $theme?: StackOnThemeT;
}

const CustomerServiceProductManuals: React.FC<PageProps> = (props: PageProps) => {
  const data = useStaticQuery<IProductManuals>(query);

  return (
    <LayoutProvider seo={false} locationSearch={props.location.search}>
      <SearchManualUI strapiProductsManuals={data.strapiProductsManuals} thanksText={''} />
    </LayoutProvider>
  );
};

export default CustomerServiceProductManuals